exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-free-ebook-js": () => import("./../../../src/pages/free-ebook.js" /* webpackChunkName: "component---src-pages-free-ebook-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recipes-js": () => import("./../../../src/pages/recipes.js" /* webpackChunkName: "component---src-pages-recipes-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-all-collections-js": () => import("./../../../src/templates/allCollections.js" /* webpackChunkName: "component---src-templates-all-collections-js" */),
  "component---src-templates-products-js": () => import("./../../../src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-templates-recipe-js": () => import("./../../../src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */)
}

