import React from 'react';
import { StoreProvider } from './src/context/StoreContext';
import mixpanel from 'mixpanel-browser';

const mixpanelToken = process.env.GATSBY_MIXPANEL_TOKEN;
mixpanel.init(mixpanelToken, { debug: true });

// Function to generate a UUID
const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

// Function to get or create a visitor ID
const getVisitorId = () => {
    let visitorId = localStorage.getItem('visitorId');
    if (!visitorId) {
        visitorId = generateUUID();
        localStorage.setItem('visitorId', visitorId);
    }
    return visitorId;
};

export const wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
);

// This function is triggered every time there is a route change
export const onRouteUpdate = ({ location, prevLocation }) => {
    const visitorId = getVisitorId();

    mixpanel.track('Page View', {
        'page path': location.pathname,
        'referrer': prevLocation ? prevLocation.pathname : null,
        'visitor id': visitorId  
    });
};
