export const loadKlaviyo = () => {
    const apiKey = process.env.GATSBY_KLAVIYO_PUBLIC_API_KEY;
  
    if (typeof window.klaviyo === 'undefined') {
      (function () {
        var b = document.createElement('script');
        b.type = 'text/javascript';
        b.async = true;
        b.src = 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=' + apiKey;
        var a = document.getElementsByTagName('script')[0];
        a.parentNode.insertBefore(b, a);
      })();
    }
  };
  