import React, { createContext, useContext, useState, useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import Client from 'shopify-buy';
import { loadKlaviyo } from '../utils/Klaviyo';

mixpanel.init(process.env.GATSBY_MIXPANEL_TOKEN, { debug: true });

const client = Client.buildClient({
  domain: 'checkout.louisearnold.au',
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
});

const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = useState({ id: '', lineItems: [], webUrl: '' });
  const [cartOpen, setCartOpen] = useState(false);

  useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutId = localStorage.getItem('shopify_checkout_id');
      if (existingCheckoutId) {
        try {
          const existingCheckout = await client.checkout.fetch(existingCheckoutId);
          if (!existingCheckout.completedAt) {
            setCheckout(existingCheckout);
            return;
          }
        } catch (e) {
          console.error('Failed to fetch existing checkout:', e);
        }
      }
      const newCheckout = await client.checkout.create();
      localStorage.setItem('shopify_checkout_id', newCheckout.id);
      setCheckout(newCheckout);
    };

    loadKlaviyo();
    initializeCheckout();
  }, []);

  const addProductToCart = async (variantId, quantity) => {
    if (!checkout) {
      console.error('Checkout has not been initialized.');
      return;
    }
    const lineItemsToAdd = [{ variantId, quantity }];
    try {
      const updatedCheckout = await client.checkout.addLineItems(checkout.id, lineItemsToAdd);
      setCheckout(updatedCheckout);
      setCartOpen(true);

      mixpanel.track('Add to Cart', {
        product_id: variantId,
        quantity: quantity,
        cart_total: updatedCheckout.lineItems.reduce((total, item) => total + item.quantity * item.variant.price, 0)
      });

      if (typeof window.klaviyo !== 'undefined') {
        window.klaviyo.track('Add to Cart', {
          product_id: variantId,
          quantity: quantity,
          cart_total: updatedCheckout.lineItems.reduce((total, item) => total + item.quantity * item.variant.price, 0)
        });
        console.log('Klaviyo event tracked');
      } else {
        console.error('Klaviyo not initialized');
      }

    } catch (error) {
      console.error('Error in addProductToCart:', error);
    }
  };

  const removeLineItem = async (lineItemId) => {
    if (!checkout) {
      console.error('Checkout has not been initialized.');
      return;
    }
    try {
      const updatedCheckout = await client.checkout.removeLineItems(checkout.id, [lineItemId]);
      setCheckout(updatedCheckout);
    } catch (error) {
      console.error('Error in removeLineItem:', error);
    }
  };

  const toggleCart = () => setCartOpen(!cartOpen);
  const closeCart = () => setCartOpen(false);
  const openCart = () => setCartOpen(true);

  return (
    <StoreContext.Provider value={{ checkout, cartOpen, addProductToCart, removeLineItem, toggleCart, closeCart, openCart }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
